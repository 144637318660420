import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Calendar, User, ChevronLeft, ChevronRight } from "lucide-react";
import DateComponent from "../../date";
import WriterComponent from "../../writer";
import { Link } from "react-router-dom";

// Custom arrow components
const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    aria-label="Previous"
    className="absolute left-4 top-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white/90 shadow-lg hover:bg-white transition-all duration-200 group"
  >
    <ChevronLeft className="w-5 h-5 text-gray-600 group-hover:text-gray-900" />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    aria-label="Next"
    className="absolute right-4 top-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white/90 shadow-lg hover:bg-white transition-all duration-200 group"
  >
    <ChevronRight className="w-5 h-5 text-gray-600 group-hover:text-gray-900" />
  </button>
);

const Popular = ({category}) => {
  const [popular, setPopular] = useState([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchPopularNews = async () => {
      try {
        const response = await fetch(`https://letempsdz.com/admin/api/category/news/${category}`);
        const data = await response.json();
        if (Array.isArray(data.news)) {
          // Sort news by date in descending order (most recent first)
          const sortedNews = data.news.sort((a, b) => {
            return new Date(b.date) - new Date(a.date); // Assuming 'date' is the field with the timestamp
          });
          setPopular(sortedNews.slice(0, 6)); // Get the top 6 after sorting
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching popular news:", error);
        setLoading(false);
      }
    };
    fetchPopularNews();
  }, [category]);
  

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-gray-900 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <section className="py-8">
      <div className="max-w-7xl mx-auto px-4">
        {/* Section Header */}
        <div className="mb-12 flex items-center justify-between">
          <div>
            <h2 className="text-3xl font-bold text-gray-900">{category}</h2>
            <p className="mt-2 text-gray-600">Les dernières actualités {category}</p>
          </div>
          <div className="hidden md:flex items-center gap-2">
            {/* Navigation dots could go here if needed */}
          </div>
        </div>

        {/* Slider */}
        <div className="relative">
          <Slider {...settings}>
            {popular.map((val, index) => (
              <div key={index} className="px-3 py-2">
                <article className="bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
                  {/* Image Container */}
                  <Link to={`/${val.slug}`} className="block relative aspect-[16/10] overflow-hidden group">
                    <img 
                      src={val.image} 
                      alt={val.title}
                      className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  </Link>

                  {/* Content */}
                  <div className="p-6">
                    {/* Category Badge */}
                    <span className="inline-block px-3 py-1 text-xs font-medium bg-gray-900/5 text-gray-900 rounded-full mb-4">
                      {val.category}
                    </span>

                    {/* Title */}
                    <Link to={`/${val.slug}`}>
                      <h3 className="text-lg font-semibold text-gray-900 mb-3 line-clamp-2 hover:text-blue-600 transition-colors">
                        {val.title}
                      </h3>
                    </Link>

                    {/* Meta Information */}
                    <div className="flex items-center gap-4 text-sm text-gray-600">
                      <div className="flex items-center gap-1.5">
                        <Calendar className="w-4 h-4" />
                        <DateComponent date={val.date} />
                      </div>
                      <div className="flex items-center gap-1.5">
                        <User className="w-4 h-4" />
                        <WriterComponent writerName={val.writerName} />
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            ))}
          </Slider>
          <div className="flex justify-center mt-8">
            <Link
            to={"/categorie/Entreprise"}
              className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Charger plus
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Popular;