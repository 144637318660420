import React from "react"
import Header from "./components/common/Header"
import "./App.css"
import Homepages from "./components/home/Homepages"
import Footer from "./components/common/Footer"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import SinglePage from "./components/singlePage/SinglePage"
import NewsDetail from "./components/singlePage/NewsDetails"
import AboutUs from "./pages/about-us"
import ContactUs from "./pages/contact-us"
import PrivacyPolicy from "./pages/privacy-policy"
import SearchResults from "./pages/searchResults"
import AllNewsPage from "./pages/AllNews"

const App = () => {
  return (
    <>
      <Router>
        <div className="flex flex-col min-h-screen justify-between">
        <Header />
        <Routes>
          <Route path="/" element={<Homepages />} /> {/* Home page route */}
          <Route path="/:slug" element={<NewsDetail />} />
          <Route path="/categorie/:category" element={<SinglePage />} /> {/* Category page */}
          <Route path="/tous-les-articles" element={<AllNewsPage />} />
          <Route path="/a-propos-de-nous" element={<AboutUs />} /> {/* About Us page */}
          <Route path="/contactez-nous" element={<ContactUs />} /> {/* Contact Us page */}
          <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
          <Route path="/search" element={<SearchResults />} />
        </Routes>
        <Footer />
        </div>
      </Router>
    </>
  )
}

export default App
