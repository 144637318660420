import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Search, X } from "lucide-react";
import Logo from "../../assets/logo_lz.webp"
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://letempsdz.com/admin/api/categories");
        const data = await response.json();
        setCategories(data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();

    // Add scroll listener for header animation
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
      setSearchQuery("");
    }
  };

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled ? 'bg-white shadow-md' : 'bg-white/80 backdrop-blur-md py-4'
    }`}>
      <div className="max-w-7xl container mx-auto px-4">
        <div className="flex items-center justify-between h-16 mx-auto">
          <div className="flex flex-row items-center gap-2">
          {/* Logo/Home Link */}
          <Link to="/" className="text-xl font-bold text-gray-800 hover:text-gray-600 transition-colors">
          <img src={Logo} alt="LetempsDZ" className="w-auto h-12" width={"50px"} height={"50px"}/>
          </Link>
          <Link to="/" className="text-xl font-bold text-gray-800 hover:text-gray-600 transition-colors">
          LetsempDZ
          </Link>
          </div>
          {/* Desktop Navigation */}
          <nav className="hidden lg:flex items-center space-x-8">
            <Link to={'/'} className="text-gray-600 hover:text-gray-900 transition-colors duration-200 text-md font-medium">Maison</Link>
            <Link to={'/tous-les-articles'} className="text-gray-600 hover:text-gray-900 transition-colors duration-200 text-md font-medium">Nouvelles</Link>
            {categories.length > 0 ? (
              categories.map((category, index) => (
                <Link
                  key={index}
                  to={`/categorie/${category.name}`}
                  className="text-gray-600 hover:text-gray-900 transition-colors duration-200 text-md font-medium"
                >
                  {category.name}
                </Link>
              ))
            ) : (
              <span className="text-gray-500 text-sm">Chargement...</span>
            )}
          </nav>

          {/* Search and Mobile Menu */}
          <div className="flex items-center space-x-4">
            {/* Search Form */}
            <form onSubmit={handleSearch} className="hidden lg:flex items-center">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Recherche..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-48 pl-4 pr-10 py-2 rounded-full bg-gray-100 focus:bg-white border-2 border-transparent focus:border-gray-300 outline-none transition-all text-sm"
                />
                <button
                  type="submit"
                  aria-label="search"
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <Search size={18} />
                </button>
              </div>
            </form>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Menu"
              className="lg:hidden p-2 rounded-lg hover:bg-gray-100 transition-colors"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden py-4 border-t">
            <nav className="flex flex-col space-y-4">
            <Link to={'/'} className="text-gray-600 hover:text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-50 transition-colors">Maison</Link>
            <Link to={'/tous-les-articles'} className="text-gray-600 hover:text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-50 transition-colors">Tous les Articles</Link>
              {categories.length > 0 ? (
                categories.map((category, index) => (
                  <Link
                    key={index}
                    to={`/categorie/${category.name}`}
                    className="text-gray-600 hover:text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-50 transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {category.name}
                  </Link>
                ))
              ) : (
                <span className="text-gray-500 px-4">Chargement...</span>
              )}
              {/* Mobile Search */}
              <form onSubmit={handleSearch} className="px-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Recherche..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full pl-4 pr-10 py-2 rounded-lg bg-gray-100 focus:bg-white border-2 border-transparent focus:border-gray-300 outline-none transition-all"
                  />
                  <button
                    type="submit"
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <Search size={18} />
                  </button>
                </div>
              </form>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;