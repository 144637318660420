import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/logo_lz.webp"
import { Mail } from 'lucide-react';
const Footer = () => {
  const [categories, setCategories] = useState([]); // State to store categories
  const [loading, setLoading] = useState(true); // State to handle loading

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://letempsdz.com/admin/api/categories");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data && Array.isArray(data.categories)) {
          setCategories(data.categories); // Set fetched categories to state
        } else {
          console.error("Unexpected data format. 'categories' may not be an array.");
        }

        setLoading(false); // Update loading state
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false); // Handle errors
      }
    };

    fetchCategories(); // Call the function to fetch categories
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className="flex flex-col">
      

      <footer className="px-4 bg-gray-800 backdrop-blur-md py-8 border-t border-gray-200 mt-auto text-white">
        <div className="container px-4">
          <div className="flex flex-col md:flex-row justify-between max-w-7xl mx-auto">
            {/* Logo and Description */}
            <div className="w-[100%] md:w-[33%] flex flex-col items-start md:items-start mb-6 md:mb-0 mx-0 md:mx-10">
            <Link to="/" aria-label="Logo Image" className="text-xl font-bold text-gray-800 hover:text-gray-600 transition-colors">
              <img src={Logo} alt="logo" className="w-auto h-12 mb-5" width={"50px"} height={"50px"}></img></Link>
              <p className="text-sm text-white text-left md:text-left">LetempsDZ est votre source d'actualités incontournable, couvrant le football, les voyages, les affaires, et bien plus encore. Nous offrons un contenu de qualité et diversifié pour vous tenir informé et engagé au quotidien.</p>
              <a href="mailto:info@letempsdz.com" className="text-blue-400 hover:text-blue-800 mt-4 inline-flex items-center">
                <Mail/>
                info@letempsdz.com
              </a>
            </div>

            {/* Latest News */}
            <div className="w-[100%] md:w-[33%]  mb-6 md:mb-0 mx-0 md:mx-10">
              <h3 className="text-lg font-semibold !text-white mb-4">Catégories</h3>
              {loading ? (
                <p className="text-center text-white">Chargement des catégories...</p>
              ) : categories.length > 0 ? (
                categories.map((category, index) => (
                  <div key={index} className="category-item mb-4">
                    <Link
                      to={`/categorie/${category.name}`}
                      className="block text-lg text-white hover:text-blue-600 transition-colors"
                    >
                      {category.name || 'Nom de la catégorie indisponible'}
                    </Link>
                  </div>
                ))
              ) : (
                <p className="text-center text-white">Aucune catégorie disponible.</p>
              )}
            </div>

            {/* Important Links */}
            <div className="w-[100%] md:w-[33%]  mb-6 md:mb-0 mx-0 md:mx-10">
              <h3 className="text-lg font-semibold !text-white mb-4">Liens importants</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/a-propos-de-nous" className="text-lg text-white hover:text-blue-600 transition-colors py-4">À propos de nous</Link>
                </li>
                <li>
                  <Link to="/contactez-nous" className="text-lg text-white hover:text-blue-600 transition-colors py-4">Contactez-nous</Link>
                </li>
                <li>
                  <Link to="/politique-de-confidentialite" className="text-lg text-white hover:text-blue-600 transition-colors py-4">Politique de confidentialité</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      {/* Legal Section */}
      <div className="bg-gray-900 py-4">
        <div className="container mx-auto text-center">
          <p className="text-sm text-gray-100">Droits d'auteur © 2024 LetempsDZ, Tous droits réservés</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
