// src/pages/AboutUs.js
import React from 'react';


const AboutUs = () => {
  document.title = "À propos de nous | LetempsDZ";

  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "À propos de nous | LetempsDZ";
  return (
    <div className="flex flex-col my-[100px] max-w-5xl mx-auto">
      <h1 className='text-[26px] font-semibold my-[6px]'>À propos de nous</h1>
      <p className='text-[18px]'>
        LetempsDZ.com est une plateforme d'actualité professionnelle. Ici, nous vous proposons uniquement du contenu intéressant que vous aimerez beaucoup. Nous avons à cœur de vous fournir la meilleure actualité, en mettant l'accent sur la fiabilité et les dernières nouveautés en France. Nous travaillons à transformer notre passion pour l’actualité en un site Web en ligne en plein essor. Nous espérons que vous apprécierez notre actualité autant que nous aimons vous la proposer.
      </p>
      <br/>
      <p className='text-[18px]'>
        Je publierai des articles plus importants pour vous tous sur mon site Web, veuillez apporter votre soutien et votre amour.
      </p>
    </div>
  );
};

export default AboutUs;
