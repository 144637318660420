import React, { useState, useEffect } from "react";
import { Calendar, User } from "lucide-react";
import DateComponent from "../../date";
import WriterComponent from "../../writer";
import { Link } from "react-router-dom";

const Music = () => {
  const [music, setMusic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visiblePosts, setVisiblePosts] = useState(4); // Track visible posts
  const [allPosts, setAllPosts] = useState([]); // Store all fetched posts

  useEffect(() => {
    const fetchMusicNews = async () => {
      try {
        const response = await fetch("https://letempsdz.com/admin/api/all/news_page?limit=11");
        const data = await response.json();

        if (data && data.news && typeof data.news === "object") {
          const allNews = Object.values(data.news).flat();
          setAllPosts(allNews); // Save all posts to allPosts
          setMusic(allNews.slice(1, visiblePosts+1)); // Show only the first 'visiblePosts' posts
        } else {
          console.error("The 'news' field is not available or is not an object.");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching music news:", error);
        setLoading(false);
      }
    };
    fetchMusicNews();
  }, [visiblePosts]); // Re-fetch when visiblePosts changes

  const loadMore = () => {
    setVisiblePosts(prev => prev + 2); // Increase the number of posts to show
  };

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-gray-900 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <section className="py-16">
      <div className="max-w-7xl mx-auto px-4">
        {/* Section Header */}
        <div className="mb-12 flex items-center justify-between">
          <div>
            <h2 className="text-3xl font-bold text-gray-900">Dernières nouvelles</h2>
            <p className="mt-2 text-gray-600">Dernières nouvelles dans le monde</p>
          </div>
        </div>

        {/* Content Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {music.map((val, index) => (
            <div key={index} className="px-3 py-2">
              <article className="bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
                {/* Image Container */}
                <Link to={`/${val.slug}`} className="block relative aspect-[16/10] overflow-hidden group">
                  <img
                    src={val.image}
                    alt={val.title}
                    className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                </Link>

                {/* Content */}
                <div className="p-6">
                  {/* Category Badge */}
                  <span className="inline-block px-3 py-1 text-xs font-medium bg-gray-900/5 text-gray-900 rounded-full mb-4">
                    {val.category}
                  </span>

                  {/* Title */}
                  <Link to={`/${val.slug}`}>
                    <h3 className="text-lg font-semibold text-gray-900 mb-3 line-clamp-2 hover:text-blue-600 transition-colors">
                      {val.title.slice(0,50)}
                    </h3>
                  </Link>

                  {/* Meta Information */}
                  <div className="flex items-center gap-4 text-sm text-gray-600">
                    <div className="flex items-center gap-1.5">
                      <Calendar className="w-4 h-4" />
                      <DateComponent date={val.date} />
                    </div>
                    <div className="flex items-center gap-1.5">
                      <User className="w-4 h-4" />
                      <WriterComponent writerName={val.writerName} />
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>

        {/* Load More Button */}
        {allPosts.length > visiblePosts && (
          <div className="flex justify-center mt-8">
            <button
              onClick={loadMore}
              className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Charger plus
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Music;
