import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import htmlParser from "html-react-parser"; // Import the HTML parser
import { Calendar, User } from "lucide-react"; // Import icons for meta info
import DateComponent from "../components/date";
import WriterComponent from "../components/writer";

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query") || ""; // Fallback if query is empty
  document.title = "Search | LetempsDZ";

  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "Search | LetempsDZ";
  useEffect(() => {
    const fetchResults = async () => {
      if (!query) {
        setError("No search query provided.");
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const response = await axios.get(
          `https://letempsdz.com/admin/api/search/news?value=${query}`
        );

        setResults(response.data.news || []);
        setError(null);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError(
          error.response?.data?.message ||
            "Internal server error. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [query]);

  const truncateText = (text, limit) => {
    if (text && text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-gray-900 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <section className="flex flex-col items-center mt-24 mb-2">
      <div className="max-w-7xl mx-auto px-4 flex-grow">
        {/* Section Header */}
        <div className="mb-6 flex items-center justify-center">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 text-center">Search</h2>
            <p className="mt-2 text-gray-600 text-center">Search > {query}</p>
          </div>
        </div>

        {/* Content Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {results.map((result, index) => (
            <div key={index} className="px-3 py-2">
              <article className="bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
                {/* Image Container */}
                <Link to={`/${result.slug}`} className="block relative aspect-[16/10] overflow-hidden group">
                  <img
                    src={result.image || 'https://via.placeholder.com/300'}
                    alt={result.title || 'No title available'}
                    className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                </Link>

                {/* Content */}
                <div className="p-6">
                  {/* Title */}
                  <Link to={`/${result.slug}`}>
                    <h3 className="text-lg font-semibold text-gray-900 mb-3 line-clamp-2 hover:text-blue-600 transition-colors">
                      {htmlParser(truncateText(result.title, 50))}
                    </h3>
                  </Link>

                  {/* Meta Information */}
                  <div className="flex items-center gap-4 text-sm text-gray-600">
                    <div className="flex items-center gap-1.5">
                      <Calendar className="w-4 h-4" />
                      <DateComponent date={result.date} />
                    </div>
                    <div className="flex items-center gap-1.5">
                      <User className="w-4 h-4" />
                      <WriterComponent writerName={result.writerName || "Auteur inconnu"} />
                    </div>
                  </div>

                  {/* Description */}
                  <p className="text-gray-700 mt-4">
                    {htmlParser(truncateText(result.description, 80))}
                  </p>
                </div>
              </article>
            </div>
          ))}
        </div>

        {results.length === 0 && !loading && !error && (
          <div className="my-auto flex-grow flex items-center justify-center">
            <p className="text-center text-lg">Aucun résultat trouvé</p>
          </div>
        )}
      </div>
    </section>
  );
};


export default SearchResults;
