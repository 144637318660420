import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Calendar, User } from "lucide-react";
import DateComponent from "../components/date";
import WriterComponent from "../components/writer";

const AllNewsPage = () => {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visiblePosts, setVisiblePosts] = useState(6); // Track visible posts

  useEffect(() => {
    setLoading(true);
    document.title = "Tous les articles | LetempsDZ";

    // Update meta title
    let metaTitleTag = document.querySelector('meta[name="title"]');
    if (!metaTitleTag) {
      metaTitleTag = document.createElement("meta");
      metaTitleTag.name = "title";
      document.head.appendChild(metaTitleTag);
    }
    metaTitleTag.content = "Tous les articles | LetempsDZ";

    // Fetch all news
    axios
      .get("https://letempsdz.com/admin/api/all/news")
      .then((response) => {
        if (response.data && response.data.news) {
            // Flatten the nested structure
            const flattenedPosts = Object.keys(response.data.news).flatMap(category =>
              response.data.news[category].map(post => ({ ...post, category }))
            );
            setNewsList(flattenedPosts);
        }
        else {
          setNewsList([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching all news:", err);
        setError("Failed to fetch news.");
        setLoading(false);
      });
  }, []);

  const loadMore = () => {
    setVisiblePosts((prev) => prev + 2); // Increase the number of posts to show
  };

  const truncateText = (text, limit) => {
    if (text && text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-gray-900 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[400px] flex items-center justify-center text-red-500">
        {error}
      </div>
    );
  }

  return (
    <section className="mt-24 h-auto mb-2">
      <div className="max-w-7xl mx-auto px-4">
        {/* Section Header */}
        <div className="mb-6 flex items-center justify-center">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 text-center">Tous les articles</h2>
            <p className="mt-2 text-gray-600 text-center">Maison > Tous les articles</p>
          </div>
        </div>

        {/* Content Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {newsList.slice(0, visiblePosts).map((news) => (
            <div key={news._id} className="px-3 py-2">
              <article className="bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
                {/* Image Container */}
                <Link to={`/${news.slug}`} className="block relative aspect-[16/10] overflow-hidden group">
                  <img
                    src={news.image || 'https://via.placeholder.com/300'}
                    alt={news.title || 'No title available'}
                    className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                </Link>

                {/* Content */}
                <div className="p-6">
                  {/* Category Badge */}
                  <span className="inline-block px-3 py-1 text-xs font-medium bg-gray-900/5 text-gray-900 rounded-full mb-4">
                    {news.category || "Non catégorisé"}
                  </span>

                  {/* Title */}
                  <Link to={`/${news.slug}`}>
                    <h3 className="text-lg font-semibold text-gray-900 mb-3 line-clamp-2 hover:text-blue-600 transition-colors">
                      {truncateText(news.title, 50)}
                    </h3>
                  </Link>

                  {/* Meta Information */}
                  <div className="flex items-center gap-4 text-sm text-gray-600">
                    <div className="flex items-center gap-1.5">
                      <Calendar className="w-4 h-4" />
                      <DateComponent date={news.date} />
                    </div>
                    <div className="flex items-center gap-1.5">
                      <User className="w-4 h-4" />
                      <WriterComponent writerName={news.writerName || "Auteur inconnu"} />
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>

        {/* Load More Button */}
        {newsList.length > visiblePosts && (
          <div className="flex justify-center mt-8 mb-8">
            <button
              onClick={loadMore}
              className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Charger plus
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default AllNewsPage;
