import React from 'react';
import { Mail } from 'lucide-react';

const ContactUs = () => {
  document.title = "Contactez-Nous | LetempsDZ";

  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "Contactez-Nous | LetempsDZ";
  return (
    <div className="flex flex-col my-[100px] max-w-5xl mx-auto">
        <h2 className="text-[26px] font-semibold my-[6px]">Comment pouvons-nous vous aider ?</h2>
        <p className="text-[18px]">
          Si vous avez des suggestions ou des questions, veuillez nous contacter en utilisant les coordonnées ci-dessous. Nous sommes impatients de vous entendre.
        </p>
        <div className="flex flex-row items-center">
          <Mail className="text-xl mr-2 hover" />
          <a className="text-black hover:text-blue-600" href="mailto:info@letempsdz.com">info@letempsdz.com</a>
        </div>
      </div>
  );
};

export default ContactUs;
