import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Clock, ArrowRight } from "lucide-react";

const Hero = () => {
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://letempsdz.com/admin/api/recent/news");
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setItem(data.news[0]);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="w-8 h-8 border-4 border-gray-900 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md p-8 bg-white rounded-2xl shadow-lg">
          <p className="text-red-500 text-center">{error}</p>
        </div>
      </div>
    );
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <div className="max-w-6xl mx-auto px-4 pt-24">
      <div className="relative grid lg:grid-cols-2 gap-8 items-center">
        {/* Image Section */}
        <div className="relative group">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 rounded-3xl transform rotate-1 transition-transform group-hover:rotate-2 opacity-10"></div>
          <div className="relative overflow-hidden rounded-2xl aspect-[4/3]">
          <Link to={`/${item.slug}`}>
            <img
              src={item.image}
              alt={item.title}
              onLoad={() => setIsImageLoaded(true)}
              className={`w-full h-full object-cover transform transition-all duration-700 ease-out scale-105 hover:scale-100 ${
                isImageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              loading="eager"
            /></Link>
          </div>
        </div>

        {/* Content Section */}
        <div className="lg:pl-8">
          {/* Category Badge */}
          <div className="mt-0">
            <span className="inline-flex items-center px-4 py-1.5 bg-gray-900 bg-opacity-5 text-gray-900 text-sm font-medium rounded-full">
              {item.category}
            </span>
          </div>

          {/* Title */}
          <Link to={`/${item.slug}`}>
          <h1 className="text-xl md:text-4xl font-bold text-gray-900 mb-6 leading-tight">
            {item.title}
          </h1></Link>

          {/* Meta Information */}
          <div className="flex items-center space-x-6 text-gray-600 mb-8">
            <div className="flex items-center">
              <Clock size={18} className="mr-2" />
              <span className="text-sm">{formatDate(item.date)}</span>
            </div>
            <div className="flex items-center">
              <span className="text-sm">Par {item.writerName}</span>
            </div>
          </div>

          {/* CTA Button */}
          <Link 
            to={`/${item.slug}`}
            className="inline-flex items-center px-6 py-3 bg-gray-900 text-white rounded-full font-medium transition-all duration-200 hover:bg-gray-800 hover:transform hover:translate-x-1"
          >
            Lire l'article
            <ArrowRight size={18} className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;