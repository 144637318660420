import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import htmlParser from "html-react-parser";
import { User } from "lucide-react";
import DateComponent from "../date";
import WriterComponent from "../writer";

const NewsDetail = () => {
  const { slug } = useParams();
  const [newsDetail, setNewsDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toc, setToc] = useState([]);
  const [showToc, setShowToc] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://letempsdz.com/admin/api/news/details/${slug}`)
      .then((response) => {
        if (response.data && response.data.news) {
          setNewsDetail(response.data.news);
          extractHeadings(response.data.news.description);
        } else {
          setNewsDetail(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching news details:", err);
        setLoading(false);
      });
  }, [slug]);


  useEffect(() => {
    if (newsDetail) {
      // Set the document title
    document.title = newsDetail.title || "LetempsDZ";

    // Check if the meta title already exists
    let metaTitleTag = document.querySelector('meta[name="title"]');
    if (!metaTitleTag) {
      // Create and append the meta title if it does not exist
      metaTitleTag = document.createElement("meta");
      metaTitleTag.name = "title";
      document.head.appendChild(metaTitleTag);
    }
    metaTitleTag.content = newsDetail.meta_title || "LetempsDZ";

    // Check if the meta description already exists
    let metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (!metaDescriptionTag) {
      // Create and append the meta description if it does not exist
      metaDescriptionTag = document.createElement("meta");
      metaDescriptionTag.name = "description";
      document.head.appendChild(metaDescriptionTag);
    }
    metaDescriptionTag.content = newsDetail.meta_description || "No description available.";

      const jsonLd = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: newsDetail.title,
        description: newsDetail.meta_description || "No description available.",
        datePublished: newsDetail.date,
        author: {
          "@type": "Person",
          name: newsDetail.writerName || "Unknown",
        },
        image: newsDetail.image || "https://via.placeholder.com/1200x600",
        url: window.location.href,
      };
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.innerHTML = JSON.stringify(jsonLd);
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [newsDetail]);

  // Extract headings using regex and set IDs to scroll smoothly
  const extractHeadings = (description) => {
    const headings = [];
    const regex = /<(h[1-6])>(.*?)<\/\1>/g; // Regex to capture all heading tags and content

    let match;
    let index = 1;
    let modifiedDescription = description;

    while ((match = regex.exec(description)) !== null) {
      const tag = match[1];  // heading tag (e.g., h1, h2)
      const text = match[2]; // heading content
      const id = `heading-${index}`;
      
      headings.push({ id, text });
      // Add id to each heading in the description
      modifiedDescription = modifiedDescription.replace(match[0], `<${tag} id="${id}">${text}</${tag}>`);
      index++;
    }

    setNewsDetail((prevDetail) => ({
      ...prevDetail,
      description: modifiedDescription, // Update the description with IDs
    }));
    setToc(headings); // Set the ToC headings
  };

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <section className="mt-24 mb-12 px-6 max-w-4xl mx-auto">
      <div className="">
        {/* Section Header */}
        <div className="mb-6">
  <div>
    <p className="my-4 text-white bg-black inline-block px-2 py-1 rounded-lg">{newsDetail.category}</p> {/* Black background for category */}
    <h1 className="text-[28px] font-semibold text-gray-900">{newsDetail.title}</h1>
  </div>
  <div className="flex items-center gap-4 text-sm text-gray-600 mb-4">
    <div className="flex items-center gap-1.5">
    <User className="w-4 h-4 text-black" />
    <WriterComponent writerName={newsDetail.writerName || "Auteur inconnu"} />
    </div>
    <div className="flex items-center gap-1.5">
    <DateComponent date={newsDetail.date} />
    </div>
  </div>
</div>


        <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
          {/* News Detail Card */}
          <div className="px-3 py-2">
            <div className="bg-white rounded-xl">
              {/* Image Container */}
              <img
                src={newsDetail.image || 'https://via.placeholder.com/1200x600'}
                alt={newsDetail.title || 'No title available'}
                className="w-full h-[400px] object-cover rounded-lg shadow-md"
              />

              {/* Table of Contents Button */}
              <button 
                className="bg-black text-white px-5 py-3 rounded-md hover:bg-blue-700 focus:outline-none transition ease-in-out duration-300 mt-4"
                onClick={() => setShowToc(!showToc)}
              >
                {"Table des matières"}
              </button>

              {/* Table of Contents */}
              {showToc && toc.length > 0 && (
                <div className="bg-gray-100 p-4 rounded-lg shadow-sm mt-6 mb-8">
                  <ul>
                    {toc.map((heading) => (
                      <li key={heading.id} className="mb-2">
                        <a href={`#${heading.id}`} className="toc-link text-blue-600 hover:underline text-lg font-medium">{heading.text}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* News Description */}
              <div className="news-body mt-8 text-gray-800">
                <div className="news-description text-lg">
                  {htmlParser(newsDetail.description || "No description available.")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsDetail;
