import React, { useEffect, useState, Suspense } from "react";
import Hero from "./hero/Hero"
import Music from "./mainContent/LatestNews";
import Popular from "./mainContent/Popular";
import axios from "axios";

const Homepages = () => {
  const [newsDetail, setNewsDetail] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://letempsdz.com/admin/api/categories");
        const data = await response.json();
        setCategories(data.categories || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    axios
      .get(`https://letempsdz.com/admin/api/meta`)
      .then((response) => {
        if (response.data) {
          setNewsDetail(response.data);
        } else {
          setNewsDetail(null);
        }
      })
      .catch((err) => {
        console.error("Error fetching news details:", err);
      });
  }, []); // Empty dependency array ensures the effect runs only once

      useEffect(() => {
        if (newsDetail) {
          // Set the document title
        document.title = newsDetail.meta_title || "LetempsDZ";
    
        // Check if the meta title already exists
        let metaTitleTag = document.querySelector('meta[name="title"]');
        if (!metaTitleTag) {
          // Create and append the meta title if it does not exist
          metaTitleTag = document.createElement("meta");
          metaTitleTag.name = "title";
          document.head.appendChild(metaTitleTag);
        }
        metaTitleTag.content = newsDetail.meta_title || "LetempsDZ";
    
        // Check if the meta description already exists
        let metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (!metaDescriptionTag) {
          // Create and append the meta description if it does not exist
          metaDescriptionTag = document.createElement("meta");
          metaDescriptionTag.name = "description";
          document.head.appendChild(metaDescriptionTag);
        }
        metaDescriptionTag.content = newsDetail.meta_description || "No description available.";
      }
      }, [newsDetail]);
    
  return (
    <>
      <Hero />
      <>
      <main>
        <div className="container">
          {/* Render Music Component */}
          <Suspense fallback={<div>Loading...</div>}>
          <Music />
          </Suspense>
          {/* Check if loading or categories are available */}
          {loading ? (
            <p>Loading...</p>
          ) : categories.length > 0 ? (
            categories.map((category) => (
              <Suspense fallback={<div>Loading...</div>}>
              <Popular key={category.name} category={category.name} />
              </Suspense>
            ))
          ) : (
            <p>No categories found.</p>
          )}
        </div>
      </main>
    </>
    </>
  )
}

export default Homepages
